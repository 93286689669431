/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-3">
            <h3 class="font-bold mb-4">Controls</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer">RAID 0</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">RAID 2</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">RAID 5</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">RAID 4</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">RAID 10</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">RAID 1</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">RAID 9</h5>
              <h5 class="dragBlock font-semibold cursor-pointer">RAID 15</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5 grid grid-cols-2 gap-4">
          <vx-card class="p-2 h-fit" :key="key">
            <!--- RAID 0 --->
            <div>
              <div class="pb-3">
                <div class="flex justify-center items-center">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-2/5" id="input0" />
                </div>
              </div>

              <div class="flex justify-center items-center mt-6">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/328518181243060224/raid0.png"
                  alt="Raid0"
                  class="w-full rounded-lg"
                  style="max-width: 250px"
                />
              </div>
            </div>
          </vx-card>

          <vx-card class="p-2 h-fit" :key="key">
            <!--- RAID 1 --->
            <div>
              <div class="pb-3">
                <div class="flex justify-center items-center">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-2/5" id="input1" />
                </div>
              </div>

              <div class="flex justify-center items-center mt-6">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/328518181243060224/raid1.png"
                  alt="Raid1"
                  class="w-full rounded-lg"
                  style="max-width: 250px"
                />
              </div>
            </div>
          </vx-card>

          <vx-card class="p-2 h-fit" :key="key">
            <!--- RAID 5 --->
            <div>
              <div class="pb-3">
                <div class="flex justify-center items-center">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-2/5" id="input2" />
                </div>
              </div>

              <div class="flex justify-center items-center mt-6">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/328518181243060224/raid5.png"
                  alt="Raid5"
                  class="w-full rounded-lg"
                  style="max-width: 254px"
                />
              </div>
            </div>
          </vx-card>

          <vx-card class="p-2 h-fit" :key="key">
            <!--- RAID 10 --->
            <div>
              <div class="pb-3">
                <div class="flex justify-center items-center">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-2/5" id="input3" />
                </div>
              </div>

              <div class="flex justify-center items-center mt-6">
                <img
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/328518181243060224/raid10.png"
                  alt="Raid5"
                  class="w-full rounded-lg"
                  style="max-width: 250px"
                />
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      key: 0,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'RAID 0') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'RAID 1') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'RAID 5') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'RAID 10') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
    shuffle,
  },
  created() {
    const temp = shuffle([0, 1, 2, 3]);
    this.raid0 = temp[0];
    this.raid1 = temp[1];
    this.raid2 = temp[2];
    this.raid3 = temp[3];
    this.key++;
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
#wordBank {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
}
</style>
